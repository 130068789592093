.root {
    margin: 0;
    font: inherit;
    border-radius: 0;
    box-shadow: none;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    font-family: inherit;
    color: #000000;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
}

.root::placeholder {
    color: rgba(85, 85, 85, 0.623);
    font-weight: 300;
}

.root:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.root:disabled {
    cursor: not-allowed;
    background-color: #fff;
    border: 1px solid #bebebe;
    color: #000000;
}

.rootError {
    /* border-color: #df1642; */
    border-bottom: 3px solid #66afe9;
}

.rootError:focus {
    border-color: red;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(223, 22, 66, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(223, 22, 66, 0.6);
}

.error {
    margin-top: 3px;
    color: red;
    font-weight: bold;
    margin-bottom: 5px;
}

select {
    height: 32px;
    line-height: 0px;
    font-size: 14px;
    padding: 0px 5px;
    font-family: inherit;
    color: #000000;
    font-weight: bold;
}
